import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusSitemap, Sitemap } from './sitemap.interface';

export default (): Sitemap[] => {
    const { allDirectusSitemap } = useStaticQuery<AllDirectusSitemap>(graphql`
    query {
    	allDirectusSitemap(sort: { fields: sort, order: ASC }) {
        edges {
          node {
            directusId
            heading
            content
          }
        }
      }
    }
  `);
    return allDirectusSitemap.edges;
};
